'use strict';

///////////////////////////////////////

const modal = document.querySelector('.modal');
const overlay = document.querySelector('.overlay');
const btnCloseModal = document.querySelector('.btn--close-modal');
const btnsOpenModal = document.querySelectorAll('.btn--show-modal');
const hamburger = document.querySelector('.hamburger');
const navMain = document.querySelector('.nav__main');
const nav = document.querySelector('.nav');
const navLinks = document.querySelector('.nav__links');
const navLink = document.querySelectorAll('.nav__link');
const btnScrollTo = document.querySelector('.btn--scroll-to');
const section1 = document.querySelector('#section--1');
const tabs = document.querySelectorAll('.operations__tab');
const tabsContainer = document.querySelector('.operations__tab-container');
const tabsContent = document.querySelectorAll('.operations__content');

// Modal Window

const openModal = function (e) {
  e.preventDefault();
  modal.classList.remove('hidden');
  // overlay.classList.remove('hidden');
};

const closeModal = function () {
  modal.classList.add('hidden');
  overlay.classList.add('hidden');
};

btnsOpenModal.forEach(btn => btn.addEventListener('click', openModal));

// btnCloseModal.addEventListener('click', closeModal);
// overlay.addEventListener('click', closeModal);

document.addEventListener('keydown', function (e) {
  if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
    closeModal();
  }
});

//Hamburger Menu

const hamburgerMenu = function () {
  hamburger.classList.toggle('active');
  navLinks.classList.toggle('active');
};

hamburger.addEventListener('click', hamburgerMenu);

navLink.forEach(n => n.addEventListener('click', hamburgerMenu));

//page navigation

document.querySelector('.nav__links').addEventListener('click', function (e) {
  e.preventDefault();
  if (e.target.classList.contains('nav__link')) {
    const id = e.target.getAttribute('href');
    const element = document.querySelector(`${id}`);
    const yOffset = -250;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
});

//tabbed components

tabsContainer.addEventListener('click', function (e) {
  //as there is another <span> child, use closest to find the common parent which contains operations_tab
  const clicked = e.target.closest('.operations__tab');
  //guard clause. Stop further execution if click on container area but not on tab, else error will occur
  if (!clicked) return;
  //remove active class from all tabs
  tabs.forEach(t => t.classList.remove('operations__tab--active'));
  //add active class to clicked target
  clicked.classList.add('operations__tab--active');
  //remove active class from all contents
  tabsContent.forEach(t => t.classList.remove('operations__content--active'));
  //find the dataset number
  const data = e.target.closest('.operations__tab').dataset.tab;
  //add active class to content aligned with the clicked target
  document
    .querySelector(`.operations__content--${data}`)
    .classList.add('operations__content--active');
});

//Menu fade animation

const handleHover = function (e) {
  if (e.target.classList.contains('nav__link')) {
    const link = e.target;
    const sibling = link.closest('.nav').querySelectorAll('.nav__link');
    sibling.forEach(el => {
      if (el !== link) el.style.opacity = this;
    });
  }
};

nav.addEventListener('mouseover', handleHover.bind(0.5));

nav.addEventListener('mouseout', handleHover.bind(1.0));

//Sticky navigation

const header = document.querySelector('.header');
const navHeight = navMain.getBoundingClientRect().height; //to get the height of the navbar

const stickyNav = function (entries) {
  const [entry] = entries;
  if (!entry.isIntersecting) {
    navMain.classList.add('sticky');
  } else {
    navMain.classList.remove('sticky');
  }
};

const headerObserver = new IntersectionObserver(stickyNav, {
  root: null, //the element that the target element (header) is intersecting; null means the entire viewport
  threshold: 0, //trigger when at 0, 0.1 (10%) etc. In this case trigger an entry to the function when the header just intersect with the viewport, so once when it enters the viewport, and a 2nd time when it disappears from the viewport
  rootMargin: `${navHeight}px`, //margin in px to trigger the intersection function
});

headerObserver.observe(header); //header is the target

//Reveal sections

const allSections = document.querySelectorAll('.section');

const revealSection = function (entries) {
  const [entry] = entries;
  if (!entry.isIntersecting) return; //to exclude when isIntersecting = false
  entry.target.classList.remove('section--hidden');
  sectionObserver.unobserve(entry.target); //on reveal, stop the observer to avoid performance issue
};

const sectionObserver = new IntersectionObserver(revealSection, {
  root: null,
  threshold: 0.1,
});

//for each section, trigger the observer and hide the section initially
allSections.forEach(function (section) {
  sectionObserver.observe(section);
  section.classList.add('section--hidden');
});

//slider
//putting entire slider into a function so as not to pollute the global namespace
const slider = function () {
  const slides = document.querySelectorAll('.slide');
  const btnRight = document.querySelector('.slider__btn--right');
  const btnLeft = document.querySelector('.slider__btn--left');
  const mainslides = document.querySelectorAll('.mainslide');
  const mainbtnRight = document.querySelector('.mainslider__btn--right');
  const mainbtnLeft = document.querySelector('.mainslider__btn--left');
  const dotsContainer = document.querySelector('.dots');
  let maincurSlide = 0;
  let curSlide = 0;

  //Functions
  const createDots = function () {
    slides.forEach(function (_, i) {
      dotsContainer.insertAdjacentHTML(
        'beforeend',
        `<button class="dots__dot" data-slide="${i}"></button>`
      );
    });
  };

  const activateDots = function (slide) {
    document
      .querySelectorAll('.dots__dot')
      .forEach(d => d.classList.remove('dots__dot--active'));
    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add('dots__dot--active');
  };

  //function to shift position X of the slides
  const goToMainSlide = function (slide) {
    mainslides.forEach(
      (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
    );
  };

  //function to shift position X of the slides
  const goToSlide = function (slide) {
    slides.forEach(
      (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
    );
  };

  //next slide to the right
  const nextMainSlide = function () {
    maincurSlide++;
    if (maincurSlide === mainslides.length) maincurSlide = 0;
    goToMainSlide(maincurSlide);
  };

  //next slide to the right
  const nextSlide = function () {
    curSlide++;
    if (curSlide === slides.length) curSlide = 0;
    goToSlide(curSlide);
    activateDots(curSlide);
  };

  const prevMainSlide = function () {
    if (maincurSlide === 0) maincurSlide = mainslides.length;
    maincurSlide--;
    goToMainSlide(maincurSlide);
  };

  const prevSlide = function () {
    if (curSlide === 0) curSlide = slides.length;
    curSlide--;
    goToSlide(curSlide);
    activateDots(curSlide);
  };

  //putting all function call related to initialization into a function
  const init = function () {
    goToMainSlide(0); //to initialize the position of the slides
    goToSlide(0);
    createDots();
    activateDots(0);
  };
  init();

  //Event Handler
  //slider button
  mainbtnRight.addEventListener('click', nextMainSlide);
  mainbtnLeft.addEventListener('click', prevMainSlide);
  btnRight.addEventListener('click', nextSlide);
  btnLeft.addEventListener('click', prevSlide);
  // setInterval(nextMainSlide, 10000);
  //keyboard arrow right and left
  document.addEventListener('keydown', function (e) {
    if (e.key === 'ArrowRight') nextSlide();
    if (e.key === 'ArrowLeft') prevSlide();
  });

  dotsContainer.addEventListener('click', function (e) {
    if (e.target.classList.contains('dots__dot')) {
      const { slide } = e.target.dataset;
      goToSlide(slide);
      activateDots(slide);
    }
  });
};
slider();
